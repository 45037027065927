import React, {PropsWithChildren} from "react";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getVw, getVwMobile} from "../../../utils/cssUtils";
import {fontWeights} from "../../styles/typography";
import {FormattedMessage} from "react-intl";
import {ActionButton} from "../ActionButton/ActionButton";

type MessageProps = {
  isSuccess: boolean,
  title: string,
  message: string,
  onRetryClick?: () => void
}


const MessageWrapper = styled('div')<{ success: boolean }>`
  @keyframes message {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: ${({success}) => success ? '#1BA148' : '#838383'};
  animation: message 1s cubic-bezier(0.5, 0, 0.5, 1);
  * {
    color: white !important;
  }
  button {
    margin-top: ${getVw(50)};
    @media only screen and (max-width: 768px) {
      margin-top: ${getVwMobile(60)};
    }
  }
`

const MessageHeader = styled('div')<{ success: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media only screen and (max-width: 768px) {
    padding: 0 ${getVwMobile(30)};
  }

  h3 {
    font-style: normal;
    font-weight: ${fontWeights.black};
    font-size: ${getVw(24)};
    line-height: ${getVw(31)};
    text-align: center;
    letter-spacing: -0.02em;
    margin: 0 0 ${getVwMobile(30)} ${getVwMobile(15)} 0;
    @media only screen and (max-width: 768px) {
      font-size: ${getVwMobile(24)};
      line-height: ${getVwMobile(31)};
      margin: 0 0 ${getVwMobile(30)} ${getVwMobile(15)} 0;

    }
  }
`

const MessageBody = styled('p')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 400 !important;
  font-size: ${getVw(16)} !important;
  line-height: ${getVw(24)} !important;
  margin: 0 !important;
  @media only screen and (max-width: 768px) {
    font-size: ${getVwMobile(16)} !important;
    line-height: ${getVwMobile(24)} !important;
    text-align: center;
    align-self: center !important;
  }
`


const StyledIcon = styled(FontAwesomeIcon)`
  font-size: ${getVw(100)};
  @media only screen and (max-width: 768px) {
    font-size: ${getVwMobile(80)};
  }
`

export const Message: React.FC<PropsWithChildren<MessageProps>> = ({isSuccess,onRetryClick}) => {


  return (
    <MessageWrapper success={isSuccess}>
      <MessageHeader success={isSuccess}>
        <StyledIcon icon={['fas', `${isSuccess ? 'circle-check' : 'face-frown'}`]}/>
        <h3><FormattedMessage id={isSuccess ? "common.success.title" : "common.error.title"}/></h3>
      </MessageHeader>
      <MessageBody>
        <FormattedMessage id={isSuccess ? "common.success.text" : "common.error.text"}/>
      </MessageBody>
      <>
        {
          onRetryClick ? (
            <ActionButton type="button" onClick={onRetryClick}>Retry</ActionButton>
          ) : null
        }
      </>
    </MessageWrapper>
  )
}
