import React, {useEffect, useState} from "react";
import defaultLogo from '../../assets/images/logo.svg'
import activeLogo from '../../assets/images/rinf.tech-logo.png'
import {ActionButton} from "../../common/components/ActionButton/ActionButton";
import {HeaderMobileBurger, HeaderNav, LogoImg, NavLink, StyledHeader} from "./Header.styles";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {FormattedMessage} from "react-intl";
import {LanguageSelector} from "./LanguageSelector/LanguageSelector";
import {scrollToContactForm} from "../../common/hooks/useScrollToContactForm";


export const Header: React.FC = () => {
  const [isActive, setIsActive] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const scrollTrigger = 60;

  const scrollHandler = () => {
    if (window.scrollY >= scrollTrigger || window.pageYOffset >= scrollTrigger) {
      setIsActive(true)
    } else {
      if (!isMobileMenuOpen) {
        setIsActive(false)
      }
    }
  }


  const mobileMenuHandler = () => {
    setIsMobileMenuOpen((prevState) => !prevState);
  }

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);
    return () => {
      window.removeEventListener('scroll', scrollHandler);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    if (isMobileMenuOpen) {
      setIsActive(true);
    } else {
      if (window.scrollY >= scrollTrigger || window.pageYOffset >= scrollTrigger) {
        setIsActive(true)
      } else {
        setIsActive(false);
      }
    }
  }, [isMobileMenuOpen]);

  const handleContact = () => {
    setIsMobileMenuOpen(false);
    scrollToContactForm();
  }

  return (
    <StyledHeader isActive={isActive}>
      <a href="/">
        <LogoImg isActive={isActive} src={isActive ? activeLogo : defaultLogo}/>
      </a>
      <HeaderNav isOpen={isMobileMenuOpen}>
        <NavLink href="https://www.rinf.tech/why/"><FormattedMessage id="header.why"/></NavLink>
        <NavLink href="https://www.rinf.tech/what/"><FormattedMessage id="header.what"/></NavLink>
        <NavLink href="https://www.rinf.tech/blog/"><FormattedMessage id="header.blog"/></NavLink>
        <NavLink href="https://www.rinf.tech/careers/" style={{marginRight: '0'}}><FormattedMessage
          id="header.careers"/></NavLink>
        <LanguageSelector/>
        <ActionButton id="header-btn" onClick={() => {
          handleContact()
        }}><FormattedMessage id="header.get-in-touch"/></ActionButton>
      </HeaderNav>
      <HeaderMobileBurger onClick={() => mobileMenuHandler()} isActive={isActive} isMobileOpen={isMobileMenuOpen}>
        <FontAwesomeIcon icon="bars"/>
      </HeaderMobileBurger>
    </StyledHeader>
  )
}
