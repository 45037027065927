import React from "react";
import {Tile, TileContent, TilesWrapper} from "./Tiles.styles";
import tile1 from './img/1.svg'
import tile2 from './img/2.svg'
import tile3 from './img/3.svg'
import {FormattedMessage} from "react-intl";

export const Tiles: React.FC = () => {


  return (
    <TilesWrapper>
      <Tile>
        <TileContent>
          <img src={tile1} alt=""/>
          <h2><FormattedMessage id="advantages.experience.title"/></h2>
          <p><FormattedMessage id="advantages.experience.text"/></p>
        </TileContent>
      </Tile>
      <Tile>
        <TileContent>
          <img src={tile2} alt=""/>
          <h2><FormattedMessage id="advantages.developers.title"/></h2>
          <p><FormattedMessage id="advantages.developers.text"/></p>
        </TileContent>
      </Tile>
      <Tile>
        <TileContent>
          <img src={tile3} alt=""/>
          <h2><FormattedMessage id="advantages.centers.title"/></h2>
          <p><FormattedMessage id="advantages.centers.text"/></p>
        </TileContent>
      </Tile>
    </TilesWrapper>
  )
}
