import React from "react";
import {CarouselWrapper, Review, ReviewHeader, ReviewHeadline, ReviewsWrapper, ReviewText} from "./Reviews.styles";
import {SectionHeader} from "../../common/common";
import img1 from './img/wolber.png'
import img2 from './img/2.png'
import {A11y, Navigation, Pagination} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
import {FormattedMessage} from "react-intl";


export const Reviews: React.FC = () => {

  return (
    <ReviewsWrapper>
      <SectionHeader><FormattedMessage id="reviews.title"/></SectionHeader>
      <CarouselWrapper>
        <Swiper
          loop
          pagination={{
            clickable: true
          }}
          rewind
          updateOnWindowResize
          autoHeight={true}
          centeredSlidesBounds
          resizeObserver
          navigation
          centeredSlides
          modules={[Navigation, Pagination, A11y]}
          breakpoints={
            {
              1: {
                slidesPerView: 1.3,
                spaceBetween: 16,
                navigation: {
                  enabled: false
                }
              },
              768: {
                slidesPerView: 1.7,
                spaceBetween: 50,
                navigation: {
                  enabled: true
                }
              },
            }
          }
        >
          <SwiperSlide>
            <Review>
              <ReviewHeader>
                <img src={img1} alt=""/>
                <ReviewHeadline>
                  <h3><FormattedMessage id="reviews.wolber.name"/></h3>
                  <p><FormattedMessage id="reviews.wolber.occupation"/></p>
                </ReviewHeadline>
              </ReviewHeader>
              <ReviewText><FormattedMessage id="reviews.wolber.text"/></ReviewText>
            </Review>
          </SwiperSlide>
          <SwiperSlide>
            <Review>
              <ReviewHeader>
                <img src={img2} alt=""/>
                <ReviewHeadline>
                  <h3><FormattedMessage id="reviews.dumitru.name"/></h3>
                  <p><FormattedMessage id="reviews.dumitru.occupation"/></p>
                </ReviewHeadline>
              </ReviewHeader>
              <ReviewText><FormattedMessage id="reviews.dumitru.text"/></ReviewText>
            </Review>
          </SwiperSlide>
        </Swiper>
      </CarouselWrapper>

    </ReviewsWrapper>
  )
}
