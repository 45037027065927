import styled from "styled-components";
import {getVw, getVwMobile} from "../../utils/cssUtils";
import {spacings} from "../../common/styles/spacings";
import {colors} from "../../common/styles/colors";
import {fontSizes, fontWeights} from "../../common/styles/typography";

export const BannerWrapper = styled('div')`
  display: flex;
  position: relative;
  background-image: url("banner.png");
  height: ${getVw(1361)};
  background-size: 60%;
  background-repeat: no-repeat;

  #curve {
    top: -5px;
    position: absolute;
    height: 102%;
    right: 39.5vw;
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }

  @media only screen and (max-width: 768px) {
    background-size: 100% 100%;
    flex-direction: column;
    height: max-content;
    padding: 0 ${getVwMobile(36)} ${getVwMobile(36)} ${getVwMobile(36)};
  }
`

export const BannerLeft = styled('div')`
  display: flex;
  width: ${getVw(800)};
  height: auto;
  color: ${colors.white};

  @media only screen and (max-width: 768px) {
    width: 100%
  }

  h1 {
    font-style: normal;
    font-weight: ${fontWeights.black};
    font-size: ${getVw(51)};
    line-height: ${getVw(67)};
    margin-bottom: ${getVw(30)};
    letter-spacing: -0.02em;
    color: ${colors.white};

    @media only screen and (max-width: 768px) {
      font-size: ${getVwMobile(33)};
      line-height: ${getVwMobile(41)};
      margin-bottom: ${getVwMobile(17)};
      margin-top: 0;
    }
  }

  p {
    font-style: normal;
    font-weight: ${fontWeights.regular};
    font-size: ${getVw(fontSizes.text.large)};
    line-height: ${getVw(31)};
    letter-spacing: -0.02em;
    color: ${colors.white};
    margin: 0;

    @media only screen and (max-width: 768px) {
      font-size: ${getVwMobile(16)};
      line-height: ${getVwMobile(24)}
    }
  }
`
export const BannerText = styled('div')`
  margin-top: ${getVw(240)};
  margin-left: ${getVw(spacings.main.x)};
  width: ${getVw(646)};

  @media only screen and (max-width: 768px) {
    width: 100%;
    margin-top: ${getVwMobile(142)};
  }
`


export const BannerRight = styled('div')`
  position: relative;
  display: flex;
  flex-direction: column;
  width: ${getVw(640)};
  padding-right: ${getVw(spacings.main.x)};
  align-items: flex-end;
  @media only screen and (max-width: 768px) {
    width: 100%;
    align-items: center;
    padding: 0;
  }
`

export const SubHeading = styled('p')`
  position: absolute;
  width: ${getVw(850)};
  right: ${getVw(spacings.main.x)};
  bottom: ${getVw(100)};
  font-style: normal;
  font-weight: 400;
  font-size: ${getVw(fontSizes.text.big)};
  line-height: ${getVw(42)};
  letter-spacing: -0.02em;
  color: ${colors.black};
  @media only screen and (max-width: 768px) {
    display: none;
  }
`

export const SubHeadingMobile = styled('div')`
  display: none;
  @media only screen and (max-width: 768px) {
    padding: ${getVwMobile(36)};
    margin: ${getVwMobile(36)} 0;
    display: flex;
    p {
      font-style: normal;
      font-weight: 400;
      font-size: ${getVwMobile(24)};
      line-height: ${getVwMobile(31)};
      letter-spacing: -0.02em;
      color: ${colors.black};
      margin: 0;
    }
  }
`


export const BannerFormWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${getVw(171)};
  @media only screen and (max-width: 768px) {
    margin-top: ${getVwMobile(50)};
    width: 100%;
  }

  a {
    font-style: normal;
    font-weight: ${fontWeights.bold};
    font-size: ${getVw(25.5)};
    line-height: ${getVw(32)};
    letter-spacing: -0.02em;
    color: ${colors.green};
    text-decoration: none;
    display: flex;
    align-items: center;

    @media only screen and (max-width: 768px) {
      font-size: ${getVwMobile(16)};
      line-height: ${getVwMobile(24)};
    }

    img {
      margin-right: ${getVw(20)};
      @media only screen and (max-width: 768px) {
        margin-right: ${getVwMobile(12)};
        width: ${getVwMobile(24)};
      }
    }

    p {
      margin-top: ${getVw(244)};
      font-style: normal;
      font-weight: ${fontWeights.regular};
      font-size: ${getVw(fontSizes.text.big)};
      line-height: ${getVw(42)};
      letter-spacing: -0.02em;
      color: ${colors.white};
    }
  }
`

export const BannerForm = styled(`form`)`
  background: ${colors.black};
  position: relative;
  box-shadow: inset 0 4px 58px ${colors.black};
  width: ${getVw(429 - 30)};
  height: ${getVw(478 - 30)};
  display: flex;
  flex-direction: column;
  color: ${colors.white};
  align-items: center;
  padding: ${getVw(30)};
  margin-top: ${getVw(30)};

  @media only screen and (max-width: 768px) {
    height: auto;
    width: fit-content;
    margin-top: ${getVwMobile(23)};
    padding: ${getVwMobile(30)};
    padding-top: ${getVwMobile(18)};
    min-width: ${getVwMobile(323)};
    min-height: ${getVwMobile(400)};
  }


  h2 {
    font-style: normal;
    font-weight: ${fontWeights.black};
    font-size: ${getVw(fontSizes.text.large)};
    line-height: ${getVw(31)};
    text-align: center;
    letter-spacing: -0.02em;
    margin-bottom: ${getVw(12)};
    @media only screen and (max-width: 768px) {
      font-size: ${getVwMobile(24)};
      line-height: ${getVwMobile(31)};
      margin-bottom: ${getVwMobile(17)};
    }
  }

  p {
    font-style: normal;
    font-weight: ${fontWeights.regular};
    font-size: ${getVw(fontSizes.text.medium)};
    line-height: ${getVw(24)};
    text-align: center;
    letter-spacing: -0.02em;
    margin-top: 0;
    margin-bottom: ${getVw(30)};

    @media only screen and (max-width: 768px) {
      font-size: ${getVwMobile(16)};
      line-height: ${getVwMobile(24)};
      margin-bottom: ${getVwMobile(25)};
    }
  }


  input {
    padding: ${getVw(30)};
  }


`
