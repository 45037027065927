export const fontSizes = {
  heading: {
    big: 64,
    small: 51,
  },
  text: {
    footer: 12,
    medium: 16,
    large: 24,
    big: 33,
  }
};


export const fontWeights = {
  thin: 300,
  regular: 400,
  bold: 500,
  black: 900
}

