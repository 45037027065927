import React from "react";
import {
  FooterAddresses,
  FooterBottom,
  FooterCopyright,
  FooterEmail,
  FooterSection,
  FooterSectionContent,
  FooterSectionLink,
  FooterSectionTitle,
  FooterSocial,
  FooterTermsAndConditions,
  FooterTop,
  FooterWrapper
} from "./Footer.styles";

import img1 from './img/EngineerOfTheFuture-white.svg'
import img2 from './img/people-of-many-talents-white.svg'
import img3 from './img/rinf.tech-help-Ukraine-logo-white.svg'
import img4 from './img/CII-white-ph7upghuhhilbspucxh2l0s6yb0sfjiutyf3dhz6dc.png'
import img5 from './img/Intel-AI-Builders-ph7v8b5bjvbi2xv7ev37vuczrqeyjtytkkc3gopxxc.png'
import img6 from './img/Developers_Romania_2021-ph7upem63tg0oksknwntg199rja205be5p44ey1yps.png'
import img7 from './img/TB-trusted-on-transparent-dark-bg.png'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {FormattedMessage} from "react-intl";
import {scrollToContactForm} from "../../common/hooks/useScrollToContactForm";

export const Footer: React.FC = () => {


  return (
    <FooterWrapper>
      <FooterTop>
        <FooterSection>
          <FooterSectionContent>
            <FooterSectionTitle href="/"><FormattedMessage id="footer.homepage"/></FooterSectionTitle>
            <FooterSectionTitle href="https://www.rinf.tech/why/"><FormattedMessage
              id="footer.why"/></FooterSectionTitle>
            <FooterSectionTitle href="https://www.rinf.tech/what/"><FormattedMessage
              id="footer.what"/></FooterSectionTitle>
            <FooterSectionTitle href="https://www.rinf.tech/what/r-d-embedded/"><FormattedMessage id="footer.embedded"/></FooterSectionTitle>
            <FooterSectionLink
              href="https://www.rinf.tech/what/r-d-embedded/embedded-software-engineering/"><FormattedMessage
              id="footer.se"/></FooterSectionLink>
            <FooterSectionLink href="https://www.rinf.tech/what/r-d-embedded/embedded-iot/"><FormattedMessage
              id="footer.iot"/></FooterSectionLink>
            <FooterSectionLink href="https://www.rinf.tech/what/r-d-embedded/devices-integration/"><FormattedMessage
              id="footer.devices"/></FooterSectionLink>
            <FooterSectionLink
              href="https://www.rinf.tech/what/r-d-embedded/hybrid-private-cloud-optimizations/"><FormattedMessage
              id="footer.cloud"/></FooterSectionLink>
            <FooterSectionLink href="https://www.rinf.tech/what/r-d-embedded/mixed-realities/"><FormattedMessage
              id="footer.mixed"/></FooterSectionLink>
            <FooterSectionLink
              href="https://www.rinf.tech/what/r-d-embedded/digital-twin-development/"><FormattedMessage
              id="footer.twin"/></FooterSectionLink>
            <FooterSectionTitle href="https://www.rinf.tech/what/robotics/"><FormattedMessage
              id="footer.robotics"/></FooterSectionTitle>
            <FooterSectionLink href="https://www.rinf.tech/what/robotics/custom-robotics/"><FormattedMessage
              id="footer.custom"/></FooterSectionLink>
            <FooterSectionLink
              href="https://www.rinf.tech/what/automotive-software-development/validation-by-robots/"><FormattedMessage
              id="footer.validation"/></FooterSectionLink>
            <FooterSectionLink href="https://www.mattrobot.ai/"><FormattedMessage id="footer.matt"/></FooterSectionLink>
            <FooterSectionLink href="https://www.adaptarobotics.com/robots/eris/"><FormattedMessage id="footer.retail"/></FooterSectionLink>
          </FooterSectionContent>
        </FooterSection>

        <FooterSection>
          <FooterSectionContent>
            <FooterSectionTitle href="https://www.rinf.tech/what/business-applications/"><FormattedMessage
              id="footer.business"/></FooterSectionTitle>
            <FooterSectionLink
              href="https://www.rinf.tech/what/business-applications/products-for-the-new-gen/"><FormattedMessage
              id="footer.newgen"/></FooterSectionLink>
            <FooterSectionLink
              href="https://www.rinf.tech/what/business-applications/custom-distributed-platform-development/"><FormattedMessage
              id="footer.distributed"/></FooterSectionLink>
            <FooterSectionLink
              href="https://www.rinf.tech/what/business-applications/create-compelling-user-experiences/"><FormattedMessage
              id="footer.ux"/></FooterSectionLink>
            <FooterSectionLink
              href="https://www.rinf.tech/what/business-applications/keep-ahead-of-the-disruptors/"><FormattedMessage
              id="footer.disruptions"/></FooterSectionLink>
            <FooterSectionLink
              href="https://www.rinf.tech/what/business-applications/hyper-automation-ready/"><FormattedMessage
              id="footer.hyper-automation"/></FooterSectionLink>
            <FooterSectionLink
              href="https://www.rinf.tech/what/business-applications/enable-the-hybrid-workforce/"><FormattedMessage
              id="footer.workforce"/></FooterSectionLink>
            <FooterSectionLink
              href="https://www.rinf.tech/what/business-applications/unleash-value-with-data/"><FormattedMessage
              id="footer.data"/></FooterSectionLink>
            <FooterSectionLink
              href="https://www.rinf.tech/what/business-applications/quality-engineering/"><FormattedMessage
              id="footer.quality"/></FooterSectionLink>
            <FooterSectionTitle href="https://www.rinf.tech/what/automotive-software-development/"><FormattedMessage
              id="footer.automotive-sd"/></FooterSectionTitle>
            <FooterSectionLink href="https://www.rinf.tech/what/automotive-software-development/electric-drive-is-now/"><FormattedMessage
              id="footer.electric"/></FooterSectionLink>
            <FooterSectionLink
              href="https://www.rinf.tech/what/automotive-software-development/engage-with-the-digital-cockpit/"><FormattedMessage
              id="footer.cockpit"/></FooterSectionLink>
            <FooterSectionLink
              href="https://www.rinf.tech/what/automotive-software-development/safety-first-for-self-driving-cars/"><FormattedMessage
              id="footer.self-driving"/></FooterSectionLink>
            <FooterSectionLink
              href="https://www.rinf.tech/what/automotive-software-development/telematics-advancements/"><FormattedMessage
              id="footer.telematics"/></FooterSectionLink>
            <FooterSectionLink
              href="https://www.rinf.tech/what/automotive-software-development/validation-by-robots/"><FormattedMessage
              id="footer.validation-by-robots"/></FooterSectionLink>
          </FooterSectionContent>
        </FooterSection>

        <FooterSection>
          <FooterSectionContent>
            <FooterSectionTitle href="https://www.rinf.tech/how/"><FormattedMessage
              id="footer.how"/></FooterSectionTitle>
            <FooterSectionLink href="https://www.rinf.tech/how/certificates/"><FormattedMessage
              id="footer.certificates"/></FooterSectionLink>
            <FooterSectionLink href="https://www.rinf.tech/how/delivery-models/"><FormattedMessage
              id="footer.services"/></FooterSectionLink>
            <FooterSectionLink href="https://www.rinf.tech/how/methodologies/"><FormattedMessage
              id="footer.methodologies"/></FooterSectionLink>
            <FooterSectionLink href="https://www.rinf.tech/how/consultancy-research/"><FormattedMessage
              id="footer.research"/></FooterSectionLink>
            <FooterSectionLink href="https://www.rinf.tech/how/success-stories/"><FormattedMessage id="footer.stories"/></FooterSectionLink>
            <FooterSectionTitle href="https://www.rinf.tech/how/industries/"><FormattedMessage id="footer.industries"/></FooterSectionTitle>
            <FooterSectionLink href="https://www.rinf.tech/how/industries/automotive/"><FormattedMessage
              id="footer.automotive"/></FooterSectionLink>
            <FooterSectionLink href="https://www.rinf.tech/how/industries/technology/"><FormattedMessage
              id="footer.technology"/></FooterSectionLink>
            <FooterSectionLink href="https://www.rinf.tech/how/industries/iot-connectivity/"><FormattedMessage
              id="footer.connectivity"/></FooterSectionLink>
            <FooterSectionLink href="https://www.rinf.tech/how/industries/retail-supply-chain/"><FormattedMessage
              id="footer.chain"/></FooterSectionLink>
            <FooterSectionLink href="https://www.rinf.tech/how/industries/fintech/"><FormattedMessage
              id="footer.fintech"/></FooterSectionLink>
            <FooterSectionLink href="https://www.rinf.tech/how/industries/research-robotics/"><FormattedMessage
              id="footer.research-robotics"/></FooterSectionLink>
            <FooterSectionTitle href="https://www.rinf.tech/blog/"><FormattedMessage
              id="footer.blog"/></FooterSectionTitle>
            <FooterSectionLink href="https://www.rinf.tech/blog/news/"><FormattedMessage
              id="footer.news"/></FooterSectionLink>
            <FooterSectionLink href="https://www.rinf.tech/blog/insights/"><FormattedMessage
              id="footer.insights"/></FooterSectionLink>
          </FooterSectionContent>
        </FooterSection>
        <FooterSection>
          <FooterSectionContent>
            <FooterSectionTitle href="https://www.rinf.tech/careers/"><FormattedMessage
              id="footer.careers"/></FooterSectionTitle>
            <FooterSectionTitle href="/" onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              scrollToContactForm()
            }}><FormattedMessage id="footer.get-in-touch"/></FooterSectionTitle>
            <FooterSocial>
              <a href="https://www.linkedin.com/company/rinftech/">
                <FontAwesomeIcon icon={['fab', 'linkedin-in']}/>
              </a>
              <a href="https://www.facebook.com/rinftech">
                <FontAwesomeIcon icon={['fab', 'facebook-f']}/>
              </a>
              <a href="https://www.youtube.com/channel/UCH_vpRcd1z-F9SYelerqsKQ">
                <FontAwesomeIcon icon={['fab', 'youtube']}/>
              </a>
              <a href="https://twitter.com/RINF_TECH">
                <FontAwesomeIcon icon={['fab', 'twitter']}/>
              </a>
            </FooterSocial>
            <FooterEmail href="/">inquiry@rinf.tech</FooterEmail>
            <FooterAddresses><FormattedMessage id="footer.locations"/></FooterAddresses>
            <div>
              <FooterCopyright><FormattedMessage id="footer.copyright"/></FooterCopyright>
              <FooterTermsAndConditions href="/"><FormattedMessage id="footer.terms"/></FooterTermsAndConditions>
            </div>
          </FooterSectionContent>
        </FooterSection>
      </FooterTop>
      <FooterBottom>
        <a href="https://engineerofthefuture.rinf.tech/">
          <img src={img1} alt=""/>
        </a>
        <a href="https://peopleofmanytalents.rinf.tech/">
          <img src={img2} alt=""/>
        </a>
        <a href="https://helpukraine.rinf.tech/">
          <img src={img3} alt=""/>
        </a>
        <a href="/" style={{pointerEvents: 'none'}}>
          <img src={img4} alt=""/>
        </a>
        <a href="/" style={{pointerEvents: 'none'}}>
          <img src={img5} alt=""/>
        </a>
        <a href="/" style={{pointerEvents: 'none'}}>
          <img src={img6} alt=""/>
        </a>
        <a href="https://techbehemoths.com/company/rinf-tech">
          <img src={img7} alt=""/>
        </a>
      </FooterBottom>
    </FooterWrapper>
  )
}
