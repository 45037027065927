import React from "react";
import {PortfolioCard, PortfolioCardsWrapper, PortfolioWrapper} from "./Portfolio.styles";
import {SectionHeader} from "../../common/common";
import {ActionButton} from "../../common/components/ActionButton/ActionButton";

import img1 from './img/1.png'
import img2 from './img/2.png'
import img3 from './img/3.png'
import {FormattedMessage} from "react-intl";


export const Portfolio: React.FC = () => {
  return (
    <PortfolioWrapper>
      <SectionHeader><FormattedMessage id="portfolio.title"/></SectionHeader>
      <p>
        <FormattedMessage id="portfolio.subtitle"/>
      </p>
      <PortfolioCardsWrapper>
        <PortfolioCard href="https://www.rinf.tech/how/industries/automotive/">
          <img src={img1} alt=""/>
          <div>
            <h3><FormattedMessage id="portfolio.project1.title"/></h3>
            <p><FormattedMessage id="portfolio.project1.text"/></p>
          </div>
        </PortfolioCard>
        <PortfolioCard href="https://www.rinf.tech/how/industries/iot-connectivity/">
          <img src={img2} alt=""/>
          <div>
            <h3><FormattedMessage id="portfolio.project2.title"/></h3>
            <p><FormattedMessage id="portfolio.project2.text"/></p>
          </div>
        </PortfolioCard>
        <PortfolioCard href="https://www.rinf.tech/how/industries/fintech/">
          <img src={img3} alt=""/>
          <div>
            <h3><FormattedMessage id="portfolio.project3.title"/></h3>
            <p><FormattedMessage id="portfolio.project3.text"/></p>
          </div>
        </PortfolioCard>
      </PortfolioCardsWrapper>
      <ActionButton onClick={() => window.location.href = 'https://www.rinf.tech/how/success-stories/ '}><FormattedMessage id="common.go-to"/></ActionButton>
    </PortfolioWrapper>
  )
}
