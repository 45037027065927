import styled from "styled-components";
import {getVw, getVwMobile} from "../utils/cssUtils";

export const SectionHeader = styled('h1')`
  font-style: normal;
  font-weight: 900;
  font-size: ${getVw(51)};
  line-height: ${getVw(67)};
  text-align: center;
  letter-spacing: -0.02em;
  margin-bottom: ${getVw(50)};
  color: #3D3D3E;
  @media only screen and (max-width: 768px) {
    font-size: ${getVwMobile(27)};
    line-height: ${getVwMobile(34)};
    margin-bottom: ${getVwMobile(40)};
    ,
  }
`

export const HintText = styled('span')<{isError: boolean}>`
  margin-top: ${getVw(10)};
  font-style: normal;
  font-weight: 450;
  font-size: ${getVw(14)};
  line-height: ${getVw(14)};
  letter-spacing: -0.02em;
  color: ${({isError}) => isError ? '#f65d5d !important' : '#dedada'};
  white-space: nowrap;
  padding-left: ${getVw(30)};
  @media only screen and (max-width: 768px) {
    margin-top: ${getVwMobile(10)};
    font-size: ${getVwMobile(12)};
    line-height: ${getVwMobile(12)};;
    
  }
`


export const FormGroup = styled('div')`
  display: flex;
  flex-direction: column;
  margin-bottom: ${getVw(20)};
  width: 100%;
  @media only screen and (max-width: 768px) {
    margin-bottom: ${getVwMobile(20)};
  }
  label {
    margin-bottom: ${getVw(4)};
    font-style: normal;
    font-weight: 500;
    font-size: ${getVw(20)};
    line-height: ${getVw(25)};;
    letter-spacing: -0.02em;
    color: #3D3D3E;

    @media only screen and (max-width: 768px) {
      margin-bottom: ${getVwMobile(5)};
      font-size: ${getVwMobile(16)};
      line-height: ${getVwMobile(20)};
    }
  }
`


export const TextField = styled('input')<{ isInvalid?: boolean }>`
  background: #FFFFFF;
  border-radius: 7px;
  padding: ${getVw(19)} ${getVw(25)};
  font-style: normal;
  font-weight: 450;
  font-size: ${getVw(16)};
  line-height: ${getVw(16)};
  letter-spacing: -0.02em;
  border: ${({isInvalid}) => isInvalid ? '1px solid red' : '1px solid transparent'};
  color: #1BA148;

  ::placeholder {
    color: rgba(36, 119, 50, 0.4);
  }

  &:focus-visible {
    outline: #1BA148;
  }

  @media only screen and (max-width: 768px) {
    padding: ${getVwMobile(18)} ${getVwMobile(30)} !important;
    font-size: ${getVwMobile(16)};
    line-height: ${getVwMobile(24)};
  }
`

export const TextArea = styled('textarea')`
  background: #FFFFFF;
  border-radius: 7px;
  padding: ${getVw(19)} ${getVw(25)};
  font-style: normal;
  font-weight: 450;
  font-size: ${getVw(16)};
  line-height: ${getVw(16)};
  letter-spacing: -0.02em;
  border: none;
  color: #1BA148;
  font-family: inherit;

  ::placeholder {
    color: rgba(36, 119, 50, 0.4);
  }

  &:focus-visible {
    outline: #1BA148;
  }

  @media only screen and (max-width: 768px) {
    padding: ${getVwMobile(8)} ${getVwMobile(17)};
    font-size: ${getVwMobile(16)};
    line-height: ${getVwMobile(24)};
  }

`
