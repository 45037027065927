import styled from "styled-components";
import {getVw, getVwMobile} from "../../utils/cssUtils";
import {spacings} from "../../common/styles/spacings";
import {colors} from "../../common/styles/colors";
import {fontSizes} from "../../common/styles/typography";

export const StyledHeader = styled('header')<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  position: fixed;
  justify-content: space-between;
  width: ${getVw(1440 - (spacings.main.x * 2))};
  top: 0;
  left: 0;
  z-index: 10;
  padding: ${getVw(17)} ${getVw(spacings.main.x)};
  background-color: ${({isActive}) => isActive ? colors.white : 'transparent'};
  background-color: ${({isActive}) => isActive ? colors.white : 'transparent'};
  transition: all 0.5s ease;
  
  @media only screen and (max-width: 700px) {
    padding: ${getVwMobile(18)} ${getVwMobile(36)};
    width: ${getVwMobile(428 - (36 * 2))};
  }
`

export const LogoImg = styled('img')<{ isActive: boolean }>`
  transition: all 0.5s ease;
  width: ${getVw(240)};
  height: auto;
  transform: ${({isActive}) => isActive ? 'scale(1)' : 'scale(0.8)'};

  @media only screen and (max-width: 700px) {
    width: ${getVwMobile(200)};
    height: auto;
  }
`

export const HeaderNav = styled('nav')<{ isOpen: boolean }>`
  display: flex;
  align-items: center;
  margin-left: ${getVw(450)};

  @media only screen and (max-width: 700px) {
    transform: scale(${({isOpen}) => isOpen ? '1' : '0'});
    display: flex;
    position: absolute;
    top: 100%;
    height: ${({isOpen}) => isOpen ? '100vh' : '0'};
    opacity: ${({isOpen}) => isOpen ? '1' : '0'};
    overflow: hidden;
    width: ${getVwMobile(428 - (63 * 2))};
    margin: 0;
    left: 0;
    transition: all 0.4s ease;
    flex-direction: column;
    padding-top: ${getVwMobile(50)};
    align-items: flex-start;
    padding-left: ${getVwMobile(63)};
    padding-right: ${getVwMobile(63)};
    background: #1BA148;

    #header-btn {
      box-shadow: 0 5.93617px 28.1968px rgba(0, 0, 0, 0.1), inset 0 5.93617px 130.596px 35.617px #247732;
      border-radius: ${getVwMobile(3)};
      font-size: ${getVwMobile(32)};
      line-height: ${getVwMobile(40)};
      font-weight: 900;
      padding: ${getVwMobile(16)};
      width: 100%;
      margin-top: ${getVwMobile(80)};
    }
  }
`

export const NavLink = styled('a')`
  font-style: normal;
  font-weight: 450;
  font-size: ${getVw(fontSizes.text.medium)};
  letter-spacing: -0.02em;
  position: relative;
  &:not(:last-child) {
    margin-right: ${getVw(35)};
  }
  transition: all 0.5s ease;
  &:before {
    content: ' ';
    background: #1BA148;
    position: absolute;
    width: 100%;
    height: 2px;
    top: 120%;
    opacity: 0;
    transition: all 0.5s ease;
  }
  &:hover {
    color: #1BA148;
    &:before {
      opacity: 1;
    }
  }

  text-decoration: none;
  cursor: pointer;
  border: none;
  outline: none;
  color: ${colors.black};

  @media only screen and (max-width: 700px) {
    font-size: ${getVwMobile(27)};
    line-height: ${getVwMobile(34)};
    margin-right: 0 !important;
    padding: ${getVwMobile(22)} 0;
    color: white;
    width: 100%;
    &:hover {
      color: white!important;
    }
    &:before {
      content: none;
    }
    &:not(:last-child) {
      border-bottom: 3px solid #247732;
    }
  }
`


export const HeaderMobileBurger = styled('button')<{ isActive: boolean, isMobileOpen: boolean }>`
  display: none;
  border: none;
  outline: none;
  background: none;
  transition: all 0.3s ease;
  @media only screen and (max-width: 700px) {
    color: ${({isActive, isMobileOpen}) => isActive ? (isMobileOpen ? 'white' : 'black') : 'white'};
    display: flex;
    position: absolute;
    height: 100%;
    align-items: center;
    width: 20%;
    background: ${({isActive, isMobileOpen}) => isActive ? (isMobileOpen ? '#1BA148' : 'transparent') : 'transparent'};
    justify-content: center;
    right: 0;
    }
  }
  svg {
    @media only screen and (max-width: 700px) {
      width: auto;
      height: ${getVwMobile(32)};
      display: flex;
    }
  }
`
