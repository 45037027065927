import React, {ButtonHTMLAttributes, PropsWithChildren} from "react";
import styled from "styled-components";
import {getVw, getVwMobile} from "../../../utils/cssUtils";

const StyledButton = styled('button')`
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: ${getVw(18)} ${getVw(30)};
  gap: ${getVw(10)};
  cursor: pointer;
  border: none;
  outline: none;
  font-style: normal;
  font-weight: 450;
  font-size: ${getVw(16)};
  letter-spacing: -0.02em;
  color: #FFFFFF;
  background: #1BA148;
  margin: 0 auto;
  transition: all 0.3s ease-in-out;
  min-width: ${getVw(150)};

  &:disabled {
    background: #adafad;
    cursor: not-allowed;
    &:hover {
      transform: none;
    }
  }

  &:hover {
    transform: scale(1.1);
    box-shadow: 0.4px 0.4px 5.9px rgba(0, 0, 0, 0.017),
    1px 0.9px 14.3px rgba(0, 0, 0, 0.024),
    2px 1.8px 26.9px rgba(0, 0, 0, 0.03),
    3.4px 3.2px 48px rgba(0, 0, 0, 0.036),
    5.8px 5.3px 89.8px rgba(0, 0, 0, 0.043),
    12px 11px 215px rgba(0, 0, 0, 0.06);
  }

  @media only screen and (max-width: 768px) {
    padding: ${getVwMobile(18)} ${getVwMobile(57)};
    line-height: ${getVwMobile(25)};;
    gap: ${getVwMobile(10)};
    font-size: ${getVwMobile(20)};
  }
`
export const ActionButton: React.FC<PropsWithChildren<ButtonHTMLAttributes<any>>> = ({onClick, children, id, ...other}) => {

  return (
    <StyledButton id={id} onClick={onClick} {...other}>
      {children}
    </StyledButton>
  )
}
