import React, {createContext, PropsWithChildren, useContext, useState} from "react";
import enTranslations from '../../../i18n/en.json';
import deTranslations from '../../../i18n/de.json';
import jpTranslations from '../../../i18n/jp.json';
import {IntlProvider} from "react-intl";

type TranslationsContextType = {
  locale: string,
  messages: Record<string, string>,
  setLocale: (locale: 'en' | 'de' | 'jp') => void;
}

const TranslationsContext = createContext<TranslationsContextType>({
  locale: navigator.language,
  messages: enTranslations,
  setLocale: (locale) => void (0)
});


export const useTranslations = () => useContext(TranslationsContext);

export const TranslationsProvider: React.FC<PropsWithChildren> = ({children}) => {

  let initialLocale = navigator.language.slice(0, 2) as 'en' | 'de' | 'jp';
  const hostname = window.location.hostname;
  
  if (hostname.startsWith('dach.')) {
    console.log("dach")
    initialLocale = 'de';
  } else if (hostname.startsWith('jp.')) {
    initialLocale = 'jp';
  } else {
    initialLocale = 'en';
  }

  const [locale, setLocale] = useState(localStorage.getItem('locale') || initialLocale);
  const [messages, setMessages] = useState<Record<string, string>>(() => {
    switch (initialLocale) {
      case 'en':
        return enTranslations;
      case 'de':
        return deTranslations;
      case 'jp':
        return jpTranslations;
      default:
        return enTranslations;
    }
  });

  const switchLanguage = (locale: 'en' | 'de' | 'jp') => {
    localStorage.setItem('locale', locale);
    setLocale(locale);
    switch (locale) {
      case 'en':
        setMessages(enTranslations);
        break;
      case 'de':
        setMessages(deTranslations);
        break;
      case 'jp':
        setMessages(jpTranslations);
        break;
      default:
        setMessages(enTranslations);
    }
  }


  return (

    <TranslationsContext.Provider value={{
      locale,
      messages,
      setLocale: switchLanguage

    }}>
      <IntlProvider locale={locale} defaultLocale={locale} messages={messages}>
        {children}
      </IntlProvider>
    </TranslationsContext.Provider>

  )
}
