import styled from "styled-components";
import {getVw, getVwMobile} from "../../utils/cssUtils";

export const ServicesWrapper = styled('div')`
  background: #F1F1F1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: ${getVw(140)};
  @media only screen and (max-width: 768px) {
    padding: ${getVwMobile(30)};
  }
`


export const ServicesAccordions = styled(`div`)`
  display: flex;
  flex-direction: column;
  width: ${getVw(900)};
  margin-top: ${getVw(10)};
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`

