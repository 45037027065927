import styled from "styled-components";
import {getVw, getVwMobile} from "../../utils/cssUtils";
import {colors} from "../../common/styles/colors";

export const FooterWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  background-color: #131313;

  a {
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    &:hover {
      color: ${colors.green};
    }
  }
`

export const FooterTop = styled('div')`
  padding: ${getVw(20)} ${getVw(20)} ${getVw(10)} ${getVw(20)};
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    padding: ${getVwMobile(20)} ${getVwMobile(20)} ${getVwMobile(10)} ${getVwMobile(20)};
  }
`


export const FooterSection = styled('div')`
  display: flex;
  width: 25%;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`

export const FooterSectionContent = styled('div')`
  display: flex;
  flex-direction: column;
  padding: ${getVw(20)};

  &:last-child {
    justify-content: space-between;
  }

  @media only screen and (max-width: 768px) {
    padding: ${getVwMobile(10)} ${getVwMobile(20)} ;
    flex-grow: 1;
  }
`

export const FooterSectionTitle = styled('a')`
  color: #CCCCCC;
  font-weight: 900;
  text-decoration: none;
  font-size: 0.8rem;

  &:not(:first-child) {
    margin-top: ${getVw(10)};
  }
`

export const FooterSectionLink = styled('a')`
  color: #CCCCCC;
  font-weight: 400;
  padding: 0 ${getVw(10)};;
  text-decoration: none;
  font-size: 0.8rem;
  @media only screen and (max-width: 768px) {
    padding: 0 ${getVwMobile(10)};
  }
`


export const FooterEmail = styled('a')`
  font-size: ${getVw(20)};
  font-weight: 700;
  line-height: ${getVw(31.2)};
  color: #fff;
  text-decoration: none;
  margin-bottom: ${getVw(10)};
  @media only screen and (max-width: 768px) {
    text-align: center;
    font-size: ${getVwMobile(24)};
    margin: ${getVwMobile(10)} 0;
    line-height: ${getVwMobile(31.2)};
  }
`

export const FooterAddresses = styled('a')`
  font-weight: 400;
  font-size: ${getVw(16)};
  color: #fff;
  text-decoration: none;
  line-height: ${getVw(24)};
  margin-bottom: ${getVw(20)};
  @media only screen and (max-width: 768px) {
    text-align: center;
    font-size: ${getVwMobile(16)};
    margin: ${getVwMobile(10)} 0;
    line-height: ${getVwMobile(24)};
  }
`

export const FooterCopyright = styled('p')`
  font-weight: 400;
  font-size: ${getVw(12)};
  color: #CCCCCC;
  line-height: ${getVw(19.2)};
  margin: 0;
  @media only screen and (max-width: 768px) {
    text-align: center;
    font-size: ${getVwMobile(13)};
    margin: ${getVwMobile(10)} 0;
    line-height: ${getVwMobile(19.2)};
    display: block;
  }
`

export const FooterTermsAndConditions = styled('a')`
  font-weight: 400;
  font-size: ${getVw(12)};
  color: #CCCCCC;
  text-decoration: none;
  line-height: ${getVw(24)};
  @media only screen and (max-width: 768px) {
    text-align: center;
    font-size: ${getVwMobile(13)};
    margin: ${getVwMobile(10)} 0;
    line-height: ${getVwMobile(19.2)};
    display: block;
  }
`


export const FooterBottom = styled('div')`
  display: flex;
  padding: ${getVw(35)};
  border-style: solid;
  border-width: 1px 0 0 0;
  border-color: #2A2A2A;
  justify-content: space-between;
  @media only screen and (max-width: 768px) {
    flex-wrap: wrap;
    align-items: center;
    gap: ${getVwMobile(20)};
    padding: ${getVwMobile(35)} ${getVwMobile(20)} ${getVwMobile(35)} ${getVwMobile(20)};
  }

  a {
    display: flex;
    @media only screen and (max-width: 768px) {
      max-width: ${getVwMobile(160)};
      height: max-content;
    }

    img {
      height: ${getVw(45)};
      width: auto;
      @media only screen and (max-width: 768px) {
        width: 100%;
        height: auto;
      }
    }
  }

`

export const FooterSocial = styled('div')`
  display: flex;
  align-items: center;
  margin: ${getVw(25)} 0;
  @media only screen and (max-width: 768px) {
    justify-content: center;
    gap: ${getVwMobile(10)};
    margin: ${getVwMobile(20)} 0;
  }

  a {
    color: white;
    font-size: ${getVw(20)};
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    @media only screen and (max-width: 768px) {
      justify-content: center;
      font-size: ${getVwMobile(20)};
    }

    &:hover {
      color: ${colors.green};
    }

    &:not(:last-child) {
      margin-right: ${getVw(25)};
    }
  }
`
