import styled from "styled-components";
import {getVw, getVwMobile} from "../../utils/cssUtils";

export const ReviewsWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${getVw(70)};
  padding-bottom: ${getVw(100)};
  background: #F1F1F1;

  h1 {
    margin-bottom: ${getVw(0)};
  }
`


export const CarouselWrapper = styled('div')`
  width: ${getVw(1440 - (68 * 2))};
  .swiper {
    padding: ${getVw(144)} 0;
    @media only screen and (max-width: 768px) {
      padding: ${getVwMobile(50)} 0;
    }
    .swiper-slide {
      display: flex;
      justify-content: center;
    }
    .swiper-wrapper {
      align-items: stretch;
    }
    .swiper-pagination-bullet {
      width: ${getVw(10)};
      height: ${getVw(10)};
      background: transparent;
      border: 2px solid #848484;
      opacity: 1 !important;
      &-active {
        background: #848484;
      }
      @media only screen and (max-width: 768px) {
        width: ${getVwMobile(15)};
        height: ${getVwMobile(15)};
      }
    }
    .swiper-button-prev, .swiper-button-next {
      color: #848484;
      @media only screen and (max-width: 768px) {
        display: none;
      }
    }
    .swiper-button-prev {
      height: 105%;
      width: ${getVw(50)};
      left: 5px;
      top: 0;
      justify-content: center;
      box-shadow: 0 0 30px ${getVw(60)} #f1f1f1;
      background: #F1F1F1;
    }
    .swiper-button-next {
      height: 105%;
      width: ${getVw(50)};
      right: 5px;
      top: 0;
      justify-content: center;
      box-shadow: 0 0 30px ${getVw(60)} #f1f1f1;
      background: #F1F1F1;
    }
  }
`

export const Review = styled('div')`
  display: flex;
  flex-direction: column;
  padding: ${getVw(48)} ${getVw(35)};
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.032) 0%, rgba(19, 19, 19, 0) 100%), #FFFFFF;
  max-width: ${getVw(730)};

  @media only screen and (max-width: 768px) {
    width: ${getVwMobile(318)};
    max-width: fit-content;
    padding: ${getVwMobile(45)} ${getVwMobile(35)};
  }
`

export const ReviewHeader = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: ${getVw(60)};

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  img {
    width: ${getVw(127)};
    height: ${getVw(127)};
    border-radius: 100%;
    margin-right: ${getVw(60)};
    @media only screen and (max-width: 768px) {
      width: ${getVwMobile(127)};
      height: ${getVwMobile(127)};
      margin-right: 0;
    }
  }
`

export const ReviewHeadline = styled('div')`
  h3 {
    font-style: normal;
    font-weight: 900;
    font-size: ${getVw(24)};
    line-height: ${getVw(25)};
    letter-spacing: -0.02em;
    color: #3D3D3E;
    margin-top: 0;
    margin-bottom: ${getVw(10)};
    text-align: left;
    @media only screen and (max-width: 768px) {
      font-size: ${getVwMobile(24)};
      line-height: ${getVwMobile(30)};
      text-align: center;
    }
  }

  p {
    font-style: normal;
    font-weight: 450;
    font-size: ${getVw(20)};
    line-height: ${getVw(25)};
    /* or 130% */
    letter-spacing: -0.02em;
    text-align: left;
    /* Main colour palette/Green */
    margin: 0;
    color: #1BA148;

    @media only screen and (max-width: 768px) {
      font-size: ${getVwMobile(16)};
      line-height: ${getVwMobile(20)};
      text-align: center;
    }
  }
`

export const ReviewText = styled('p')`
  font-style: italic;
  font-weight: 450;
  font-size: ${getVw(16)};
  line-height: ${getVw(20)};
  letter-spacing: -0.02em;
  color: #1BA148;

  @media only screen and (max-width: 768px) {
    font-size: ${getVwMobile(16)};
    line-height: ${getVwMobile(20)};
    text-align: left;
  }
`
