import React from "react";
import {ContactForm, ContactInfo, ContactWrapper} from "./Contact.styles";
import {FormGroup, HintText, SectionHeader, TextArea, TextField} from "../../common/common";
import {ActionButton} from "../../common/components/ActionButton/ActionButton";

import phone from './img/smartphone.svg'
import geo from './img/geo.svg'
import {useGetInTouch} from "../../common/hooks/useGetInTouch";
import {useForm} from "react-hook-form";
import {Spinner} from "../../common/components/Spinner/Spinner";
import {Message} from "../../common/components/Message/Message";
import {FormattedMessage, useIntl} from "react-intl";
import * as EmailValidator from 'email-validator';


type Form = {
  email: string,
  name: string,
  phone: string,
  message: string,
}

export const Contact: React.FC = () => {

  const {formatMessage} = useIntl();
  const {register, handleSubmit, formState: {errors, isValid, isDirty}} = useForm<Form>({
    mode: "onChange"
  });

  const {mutate: getInTouch, isLoading, isError, isSuccess, reset} = useGetInTouch({
    // onSuccess: () => {
    //   console.log('success')
    // },
    // onError: () => {
    //   console.log('error')
    // }
  });
  const onSubmit = (data: Form) => {
    getInTouch(data);
  }

  return (
    <ContactWrapper id="contact-form">
      <SectionHeader><FormattedMessage id="contact.title"/></SectionHeader>
      <ContactInfo>
        <div>
          <img src={phone} alt=""/>
          <a href="tel:+40754022459">+40 754 022 459</a>
          <a href="mailto:berlin@rinf.tech">berlin@rinf.tech</a>
        </div>
        <div>
          <img src={geo} alt=""/>
          <a href="https://g.page/Gontardstrasse-11-3510?share">
            Alexanderplatz - Gontardstr. 11, 10178<br/>
            Berlin, Deutschland
          </a>
        </div>
      </ContactInfo>

      <p><FormattedMessage id="contact.subtitle.bold"/></p>
      <span className="contact-subtitle"><FormattedMessage id="contact.subtitle.normal"/></span>

      <ContactForm onSubmit={handleSubmit(onSubmit)}>
        {(isLoading && !isError) && (
          <Spinner/>
        )}
        {
          (!isSuccess && !isError && !isLoading) && (
            <>
              <FormGroup>
                <label><FormattedMessage id="common.email"/></label>
                <TextField placeholder={formatMessage({id: "common.email"})} type="email" isInvalid={!!errors?.email}
                           {...register('email',
                             {
                               required: formatMessage({id: 'common.required.email'}),
                               validate: {
                                 value: (value) => EmailValidator.validate(value) || formatMessage({id: 'common.invalid.email'})
                               },
                               minLength: {
                                 value: 2,
                                 message: formatMessage({id: 'common.invalid.email'})
                               },
                             })}
                />
                <HintText className="contact-hint" isError={true}>{errors?.email?.message}</HintText>
              </FormGroup>
              <FormGroup>
                <label><FormattedMessage id="common.name"/></label>
                <TextField placeholder={formatMessage({id: "common.name"})} type="text" isInvalid={!!errors?.name}
                           {...register('name',
                             {
                               required: formatMessage({id: 'common.required.name'}),
                               pattern: {
                                 value: /^[ a-zA-Z\-’]+$/,
                                 message: formatMessage({id: 'common.invalid.name'})
                               },
                               minLength: {
                                 value: 2,
                                 message: formatMessage({id: 'common.invalid.name'})
                               },
                             })}
                />
                <HintText className="contact-hint" isError={true}>{errors?.name?.message}</HintText>
              </FormGroup>
              <FormGroup>
                <label><FormattedMessage id="common.phone"/></label>
                <TextField placeholder={formatMessage({id: "common.phone"})} type="tel" isInvalid={!!errors?.phone}
                           {...register('phone',
                             {
                               required: formatMessage({id: 'common.required.phone'}),

                             })}
                />
                <HintText className="contact-hint" isError={false}><FormattedMessage id="common.example"/>: +123995378983</HintText>
                <HintText className="contact-hint" isError={true}>{errors?.phone?.message}</HintText>
              </FormGroup>
              <FormGroup>
                <label><FormattedMessage id="common.message"/></label>
                <TextArea placeholder={formatMessage({id: "common.message"})} rows={15}
                          {...register('message',
                            {
                              required: false,
                            })}
                />
              </FormGroup>
              <ActionButton disabled={(isDirty && !isValid)}><FormattedMessage id="common.send"/></ActionButton>
            </>
          )
        }
        <>
          {
            isSuccess && (
              <Message isSuccess title={formatMessage({id: "common.success.title"})}
                       message={formatMessage({id: "common.success.text"})}/>
            )
          }
          {
            isError && (
              <Message isSuccess={false} title={formatMessage({id: "common.success.title"})} onRetryClick={reset}
                       message={formatMessage({id: "common.success.text"})}/>
            )
          }
        </>

      </ContactForm>

    </ContactWrapper>
  )
}
