import React from "react";
import {
  BannerForm,
  BannerFormWrapper,
  BannerLeft,
  BannerRight,
  BannerText,
  BannerWrapper,
  SubHeading
} from "./Banner.styles";
import {ActionButton} from "../../common/components/ActionButton/ActionButton";
import {FormGroup, HintText, TextField} from "../../common/common";
import phoneIcon from './img/phone.svg'
import {useForm} from "react-hook-form";
import {useGetInTouch} from "../../common/hooks/useGetInTouch";
import {Spinner} from "../../common/components/Spinner/Spinner";
import {Message} from "../../common/components/Message/Message";
import {FormattedMessage, useIntl} from "react-intl";
import phone from "phone";

type Form = {
  name: string,
  phone: string
}

export const Banner: React.FC = () => {
  const intl = useIntl();
  const {register, handleSubmit, formState: {errors, isDirty, isValid}} = useForm<Form>({
    mode: "onChange"
  });

  const {mutate: getInTouch, isLoading, isError, isSuccess, reset} = useGetInTouch({
    // onSuccess: () => {
    //   localStorage.setItem('successfullyContacted', JSON.stringify(new Date()));
    // },
    // onError: () => {
    //   console.log('error')
    // }
  });

  const onSubmit = (data: Form) => {
    getInTouch(data);
  }
  return (
    <BannerWrapper>
      <BannerLeft>
        <BannerText>
          <h1>
            <FormattedMessage id="banner.title"/>
          </h1>
          <p><FormattedMessage id="banner.subtitle"/></p>
        </BannerText>
      </BannerLeft>
      <BannerRight>
        <svg id="curve" viewBox="-85.662 -1.325 947.18 1347.91" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M -85.662 1346.585 C -80.982 1341.965 691.65 668.31 691.65 668.31 C 733.41 627.09 766.55 578.05 789.16 524.02 C 811.78 469.99 823.43 412.03 823.43 353.5 C 823.43 294.97 811.78 237.01 789.16 182.98 C 766.54 128.95 733.4 79.91 691.65 38.69 L 652.78 0 L 861.518 -1.325 L 861.518 1346.585 L -85.662 1346.585 Z"
            style={
              {
                strokeWidth: 0,
                fill: 'rgb(255, 255, 255)'
              }
            }/>
        </svg>

        <BannerFormWrapper>
          <a href="tel:+40754022459"><img src={phoneIcon} alt=""/> +40 754 022 459</a>
          <BannerForm onSubmit={handleSubmit(onSubmit)}>
            {(isLoading && !isError) && (
              <Spinner/>
            )}
            {(!isSuccess && !isError && !isLoading) && (
              <>
                <h2><FormattedMessage id="banner.form.title"/></h2>
                <p><FormattedMessage id="banner.form.subtitle"/></p>
                <FormGroup>
                  <TextField placeholder={intl.formatMessage({id: 'common.name'})} type="text"
                             isInvalid={!!errors?.name}
                             {...register('name',
                               {
                                 required: intl.formatMessage({id: 'common.required.name'}),
                                 pattern: {
                                   value: /^[ a-zA-Z\-’]+$/,
                                   message: intl.formatMessage({id: 'common.invalid.name'})
                                 },
                                 minLength: {
                                   value: 2,
                                   message: intl.formatMessage({id: 'common.invalid.name'})
                                 },
                               })}
                  />
                  <HintText isError={true}>{errors?.name?.message}</HintText>
                </FormGroup>
                <FormGroup>
                  <TextField placeholder={intl.formatMessage({id: 'common.phone'})} type="tel"
                             isInvalid={!!errors?.phone}
                             {...register('phone',
                               {
                                 required: intl.formatMessage({id: 'common.required.phone'}),
                                 validate: {
                                   value: (value) => phone(value).isValid || intl.formatMessage({id: 'common.invalid.phone'})
                                 }
                               })}
                  />
                  <HintText isError={false}><FormattedMessage id="common.example"/>: +123995378983</HintText>
                  <HintText isError={true}>{errors?.phone?.message}</HintText>
                </FormGroup>
                <ActionButton disabled={(isDirty && !isValid)}><FormattedMessage id="common.send"/></ActionButton>
              </>
            )}
            <>
              {
                (isSuccess) && (
                  <Message isSuccess title={intl.formatMessage({id: "common.success.title"})}
                           message={intl.formatMessage({id: "common.success.text"})}/>
                )
              }
              {
                isError && (
                  <Message isSuccess={false} title={intl.formatMessage({id: "common.success.title"})} onRetryClick={reset}
                           message={intl.formatMessage({id: "common.success.text"})}/>
                )
              }
            </>
          </BannerForm>
        </BannerFormWrapper>
        <SubHeading>
          <strong><FormattedMessage id="banner.subheading.bold"/></strong>,
          <br/>
          <FormattedMessage
            id="banner.subheading.normal"/>
        </SubHeading>
      </BannerRight>
    </BannerWrapper>
  )
}
