import styled from "styled-components";
import {getVw, getVwMobile} from "../../utils/cssUtils";
import {spacings} from "../../common/styles/spacings";
import {fontSizes, fontWeights} from "../../common/styles/typography";
import {colors} from "../../common/styles/colors";

export const TilesWrapper = styled('div')`
  display: flex;
  align-items: stretch;
  padding: ${getVw(spacings.main.x)};
  justify-content: space-between;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`


export const Tile = styled('div')`
  width: 33%;
  background: linear-gradient(180deg, #F0F0F0 0%, #FFFFFF 52.6%);
  border-radius: 241.136px 241.136px 0 0;

  @media only screen and (max-width: 768px) {
    width: 100%;
    border-radius: ${getVwMobile(241)} ${getVwMobile(241)} 0 0;
    &:not(:last-child){
      margin-bottom: ${getVwMobile(40)};
    }
  }
`

export const TileContent = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${getVw(120)} ${getVw(40)} ${getVw(40)} ${getVw(40)};

  @media only screen and (max-width: 768px) {
    padding: ${getVwMobile(60)} ${getVwMobile(30)} ${getVwMobile(30)} ${getVwMobile(30)};
  }
  
  img {
    width: ${getVw(104)};
    height: auto;
    @media only screen and (max-width: 768px) {
      width: ${getVwMobile(70)};
    }
  }

  h2 {
    font-weight: 450;
    font-size: ${getVw(fontSizes.text.large)};
    line-height: 31px;
    text-align: center;
    letter-spacing: -0.02em;
    color: ${colors.green};

    @media only screen and (max-width: 768px) {
      font-size: ${getVwMobile(24)};
      line-height: ${getVwMobile(31)};
      margin: 0;
      margin-bottom: ${getVwMobile(16)};
      margin-top: ${getVwMobile(25)};
    }
  }

  p {
    font-weight: ${fontWeights.regular};
    font-size: ${getVw(fontSizes.text.medium)};
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #848484;
    @media only screen and (max-width: 768px) {
      font-size: ${getVwMobile(16)};
      line-height: ${getVwMobile(24)};
    }
  }
`
