import React from "react";
import {
  TechnologiesRow,
  TechnologiesWrapper,
  TechnologyImage,
  TechnologyText,
  TechnologyTextWrapper
} from "./Technologies.styles";
import {SectionHeader} from "../../common/common";


import img1 from './img/1.png'
import img2 from './img/2.png'
import img3 from './img/3.png'
import img4 from './img/4.png'
import {FormattedMessage} from "react-intl";


export const Technologies: React.FC = () => {

  return (
    <TechnologiesWrapper>
      <SectionHeader><FormattedMessage id="tech.title"/></SectionHeader>
      <TechnologiesRow>
        <TechnologyImage src={img1} isOdd={true}/>
        <TechnologyTextWrapper>
          <TechnologyText>
            <h2><FormattedMessage id="tech.tiles.apps.title"/></h2>
            <p><FormattedMessage id="tech.tiles.apps.text"/></p>
          </TechnologyText>
        </TechnologyTextWrapper>
      </TechnologiesRow>
      <TechnologiesRow>
        <TechnologyImage src={img2} isOdd={false}/>
        <TechnologyTextWrapper>
          <TechnologyText>
            <h2><FormattedMessage id="tech.tiles.embedded.title"/></h2>
            <p><FormattedMessage id="tech.tiles.embedded.text"/></p>
          </TechnologyText>
        </TechnologyTextWrapper>
      </TechnologiesRow>
      <TechnologiesRow>
        <TechnologyImage src={img3} isOdd={true}/>
        <TechnologyTextWrapper>
          <TechnologyText>
            <h2><FormattedMessage id="tech.tiles.automotive.title"/></h2>
            <p><FormattedMessage id="tech.tiles.automotive.text"/></p>
          </TechnologyText>
        </TechnologyTextWrapper>
      </TechnologiesRow>
      <TechnologiesRow>
        <TechnologyImage src={img4} isOdd={false}/>
        <TechnologyTextWrapper>
          <TechnologyText>
            <h2><FormattedMessage id="tech.tiles.robotics.title"/></h2>
            <p><FormattedMessage id="tech.tiles.robotics.text"/></p>
          </TechnologyText>
        </TechnologyTextWrapper>
      </TechnologiesRow>
    </TechnologiesWrapper>
  )
}
