import React, {useState} from "react";
import {ServicesAccordions, ServicesWrapper} from "./Services.styles";
import {SectionHeader} from "../../common/common";
import {Accordion} from "../../common/components/Accordion/Accordion";
import {FormattedMessage, useIntl} from "react-intl";


export const Services: React.FC = () => {
  const [open, setOpen] = useState<string | null>("0");
  const intl = useIntl();

  const handleClick = (id: string) => {
    setOpen(open === id ? null: id);
  }

  return (
    <ServicesWrapper>
      <SectionHeader><FormattedMessage id="services.title"/></SectionHeader>
      <ServicesAccordions>
        <Accordion id="0" onOpen={handleClick} title={intl.formatMessage({id: "services.accordions.custom.title"})} isOpen={open === "0"}>
          <FormattedMessage id="services.accordions.custom.text"/>
        </Accordion>
        <Accordion id="1" onOpen={handleClick} title={intl.formatMessage({id: "services.accordions.staff.title"})} isOpen={open === "1"}>
          <FormattedMessage id="services.accordions.staff.text"/>
        </Accordion>
        <Accordion id="2" onOpen={handleClick} title={intl.formatMessage({id: "services.accordions.consulting.title"})} isOpen={open === "2"}>
          <FormattedMessage id="services.accordions.consulting.text"/>
        </Accordion>
        <Accordion id="3" onOpen={handleClick} title={intl.formatMessage({id: "services.accordions.delivery.title"})} isOpen={open === "3"}>
          <FormattedMessage id="services.accordions.delivery.text"/>
        </Accordion>
        <Accordion id="4" onOpen={handleClick} title={intl.formatMessage({id: "services.accordions.robotics.title"})} isOpen={open === "4"}>
          <FormattedMessage id="services.accordions.robotics.text"/>
        </Accordion>
      </ServicesAccordions>
    </ServicesWrapper>
  )
}
