import styled from "styled-components";
import {getVw, getVwMobile} from "../../utils/cssUtils";

export const ContactWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${getVw(70)};
  background: #F1F1F1;
  padding-left: ${getVw(280)};
  padding-right: ${getVw(280)};
  padding-bottom: ${getVw(164)};
  
  .contact-hint {
    color: #838383;
  }

  @media only screen and (max-width: 768px) {
    padding-left: ${getVwMobile(40)};
    padding-right: ${getVwMobile(40)};
    padding-bottom: ${getVwMobile(50)};
  }

  h1 {
    @media only screen and (min-width: 768px) {
      font-weight: 900;
      font-size: ${getVw(64)};
      line-height: ${getVw(81)};
      letter-spacing: -0.02em;
      margin-bottom: ${getVw(80)};
    }
  }

  p {
    font-style: normal;
    font-weight: 900;
    font-size: ${getVw(33)};;
    line-height: ${getVw(42)};;
    text-align: center;
    letter-spacing: -0.02em;
    color: #3D3D3E;
    margin-top: 0;
    @media only screen and (max-width: 768px) {
      text-align: left;
      align-self: flex-start;
      font-size: ${getVwMobile(20)};
      line-height: ${getVwMobile(25.3)};
      max-width: ${getVwMobile(302)};
      margin-bottom: ${getVwMobile(10)};
    }
  }

  span.contact-subtitle {
    font-weight: 300;
    font-size: ${getVw(33)};;;
    line-height: ${getVw(47)};
    text-align: center;
    letter-spacing: -0.02em;
    color: #3D3D3E;
    @media only screen and (max-width: 768px) {
      text-align: left;
      align-self: flex-start;
      font-size: ${getVwMobile(13)};;;
      line-height: ${getVwMobile(16)};
      max-width: ${getVwMobile(302)};
    }
  }
`
export const ContactForm = styled('form')`
  display: flex;
  position: relative;
  flex-direction: column;
  margin-top: ${getVw(100)};
  align-items: center;
  width: 100%;
  min-height: ${getVw(400)};
  
  textarea {
    resize: vertical;
  }
  button {
    margin-top: ${getVw(50)};
    @media only screen and (min-width: 768px) {
      padding: ${getVw(18)} ${getVw(59)};
    }
  }
  @media only screen and (max-width: 768px) {
    min-height: ${getVwMobile(500)};
  }
`

export const ContactInfo = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: ${getVw(100)};
  width: 100%;


  @media only screen and (max-width: 768px) {
    flex-direction: column;
    gap: ${getVwMobile(17)} ;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    img {
      width: ${getVw(90)};
      height: auto;
      margin-bottom: ${getVw(20)};
      @media only screen and (max-width: 768px) {
        width: ${getVwMobile(45)};
        height: auto;
        margin-bottom: ${getVw(17)};
      }
    }

    a {
      font-style: normal;
      font-weight: 450;
      font-size: ${getVw(24)};
      line-height: ${getVw(31)};
      text-align: center;
      letter-spacing: -0.02em;
      color: #1BA148;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
      @media only screen and (max-width: 768px) {
        font-size: ${getVwMobile(16)};
        line-height: ${getVwMobile(24)};
        max-width: ${getVwMobile(150)};
        &:hover {
          text-decoration: none;
        }
      }
    }
  }

`
