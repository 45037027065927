import React from "react";
import styled from "styled-components";


const StyledSpinner = styled('div')`

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  position: absolute;
  width: 105%;
  height: 105%;
  top: -2.5%;
  left: -2.5%;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(2px);

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #1BA148;
    border-radius: 50%;
    animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #1BA148 transparent transparent transparent;
  }

  div:nth-child(1) {
    animation-delay: -0.45s;
  }

  div:nth-child(2) {
    animation-delay: -0.3s;
  }

  div:nth-child(3) {
    animation-delay: -0.15s;
  }

`

export const Spinner: React.FC = () => {

  return (
    <StyledSpinner className="spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </StyledSpinner>
  )
}
