import styled from "styled-components";
import {getVw, getVwMobile} from "../../utils/cssUtils";

export const BenefitsWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  padding: ${getVw(68)} ${getVw(150)};

  h1 {
    margin-bottom: ${getVw(100)};
    @media only screen and (max-width: 768px) {
      margin-bottom: ${getVwMobile(50)};
    }
  }

  @media only screen and (max-width: 768px) {
    padding: ${getVwMobile(50)};
  }

`

export const BenefitsGrid = styled('div')`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${getVw(70)};
  @media only screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    gap: ${getVwMobile(50)};
  }
`

export const BenefitTile = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    margin-bottom: ${getVw(20)};
    @media only screen and (max-width: 768px) {
      margin-bottom: ${getVwMobile(10)};
      width: ${getVwMobile(80)};
      height: auto;
    }
  }

  p {
    font-style: normal;
    font-weight: 450;
    font-size: ${getVw(24)};
    line-height: ${getVw(31)};
    text-align: center;
    letter-spacing: -0.02em;
    color: #1BA148;
    margin-bottom: ${getVw(10)};
    @media only screen and (max-width: 768px) {
      font-size: ${getVwMobile(24)};
      line-height: ${getVwMobile(31)};
      margin-bottom: ${getVwMobile(10)};
    }
  }

  span {
    font-style: normal;
    font-weight: 400;
    font-size: ${getVw(16)};
    line-height: ${getVw(24)};
    text-align: center;
    letter-spacing: -0.02em;
    color: #848484;
    @media only screen and (max-width: 768px) {
      font-size: ${getVwMobile(16)};
      line-height: ${getVwMobile(24)};
    }
  }

`
