const FRAME_WIDTH = 1440;
const FRAME_WIDTH_MOBILE = 428;
const FRAME_HEIGHT = 1440;



export const MOBILE_MAX_WIDTH = 700;

export const getVw = (width: number) => `${100 * width / FRAME_WIDTH}vw`;

export const getVwMobile = (width: number) => `${100 * width / FRAME_WIDTH_MOBILE}vw`;

export const getVh = (height: number) => `${100 * height / FRAME_HEIGHT}vh`;




