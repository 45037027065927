import React from "react";
import {BenefitsGrid, BenefitsWrapper, BenefitTile} from "./Benefits.styles";
import {SectionHeader} from "../../common/common";

import img1 from './img/1.svg'
import img2 from './img/2.svg'
import img3 from './img/3.svg'
import img4 from './img/4.svg'
import img5 from './img/5.svg'
import img6 from './img/6.svg'
import {FormattedMessage} from "react-intl";

export const Benefits: React.FC = () => {
  return (
    <BenefitsWrapper>
      <SectionHeader><FormattedMessage id="benefits.title"/></SectionHeader>
      <BenefitsGrid>
        <BenefitTile>
          <img src={img1} alt=""/>
          <p><FormattedMessage id="benefits.certs.title"/></p>
          <span><FormattedMessage id="benefits.certs.text"/></span>
        </BenefitTile>
        <BenefitTile>
          <img src={img2} alt=""/>
          <p><FormattedMessage id="benefits.models.title"/></p>
          <span><FormattedMessage id="benefits.models.text"/></span>
        </BenefitTile>
        <BenefitTile>
          <img src={img3} alt=""/>
          <p><FormattedMessage id="benefits.projects.title"/></p>
          <span><FormattedMessage id="benefits.projects.text"/></span>
        </BenefitTile>
        <BenefitTile>
          <img src={img4} alt=""/>
          <p><FormattedMessage id="benefits.lab.title"/></p>
          <span><FormattedMessage id="benefits.lab.text"/></span>
        </BenefitTile>
        <BenefitTile>
          <img src={img5} alt=""/>
          <p><FormattedMessage id="benefits.center.title"/></p>
          <span><FormattedMessage id="benefits.center.text"/></span>
        </BenefitTile>
        <BenefitTile>
          <img src={img6} alt=""/>
          <p><FormattedMessage id="benefits.platform.title"/></p>
          <span><FormattedMessage id="benefits.platform.text"/></span>
        </BenefitTile>
      </BenefitsGrid>
    </BenefitsWrapper>
  )
}
