import styled from "styled-components";
import {getVw, getVwMobile} from "../../../utils/cssUtils";

export const AccordionWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: ${getVw(20)};
    @media only screen and (max-width: 768px) {
      margin-bottom: ${getVwMobile(10)};
    }
  }
`

export const AccordionHeader = styled('div')`
  display: flex;
  padding: 0 ${getVw(45)};
  padding-right: 0;
  justify-content: space-between;
  background: white;
  * {
    cursor: pointer;
  }
  @media only screen and (max-width: 768px) {
    padding: 0 ${getVwMobile(13)};
    padding-right: 0;
  }
`

export const AccordionTitle = styled('h4')`
  font-weight: 700;
  font-size: ${getVw(33)};
  line-height: ${getVw(42)};
  letter-spacing: -0.02em;
  color: #3D3D3E;
  margin: ${getVw(28)} 0;

  @media only screen and (max-width: 768px) {
    font-size: ${getVwMobile(16)};
    line-height: ${getVwMobile(20)};
  }
`

export const AccordionButton = styled('button')<{ isOpen?: boolean }>`
  display: flex;
  width: 20%;
  background: #1BA148;
  border-radius: ${({isOpen}) => `50px 0 0 ${isOpen ? '0' : '50'}px`};
  color: white;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  transition: all 0.2s ease;

  @media only screen and (max-width: 768px) {
    font-size: ${getVwMobile(16)};
    line-height: ${getVwMobile(20)};
    width: 13%;
    border-radius: ${({isOpen}) => `${getVwMobile(25)} 0 0 ${isOpen ? '0' : `${getVwMobile(25)}`}`};
  }
  
  img {
    @media only screen and (max-width: 768px) {
      width: ${getVwMobile(20)};
    }
  }
`

export const AccordionContent = styled('div')`
  background: #1BA148;
  padding: ${getVw(45)};
  @media only screen and (max-width: 768px) {
    padding: ${getVwMobile(20)};
  }

  p {
    font-style: normal;
    margin: 0;
    font-weight: 400;
    font-size: ${getVw(16)};
    line-height: ${getVw(24)};
    letter-spacing: -0.02em;
    color: #FFFFFF;

    @media only screen and (max-width: 768px) {
      font-size: ${getVwMobile(16)};
      line-height: ${getVwMobile(24)};
    }
  }
`
