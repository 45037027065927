import styled from "styled-components";
import {getVw, getVwMobile} from "../../utils/cssUtils";

export const TechnologiesWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 ${getVw(68)} ${getVw(129)} ${getVw(68)};
  @media only screen and (max-width: 768px) {
    padding: 0
  }
`


export const TechnologiesRow = styled('div')`
  display: flex;
  align-items: stretch;
  position: relative;

  :nth-child(odd) {
    flex-direction: row-reverse;

    img {
      border-bottom-right-radius: 20%
    }
  }

  :nth-child(even) {
    img {
      border-bottom-left-radius: 20%
    }
  }

  @media only screen and (max-width: 768px) {
    :nth-child(odd) {
      flex-direction: column;
      img {
        margin-right: ${getVwMobile(39)};
        border-bottom-right-radius: 15vw
      }
    }

    :nth-child(even) {
      flex-direction: column;
      img {
        margin-left: ${getVwMobile(39)};
        border-bottom-left-radius: 15vw;
      }
    }
  }
`

export const TechnologyImage = styled('img')<{ isOdd: boolean }>`
  width: 50%;
  height: auto;

  @media only screen and (max-width: 768px) {
    height: ${getVwMobile(198)};
    width: auto;
    object-fit: cover;
  }
`

export const TechnologyTextWrapper = styled('div')`
  display: flex;
  width: 50%;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`


export const TechnologyText = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: ${getVw(60)};
  justify-content: center;
  @media only screen and (max-width: 768px) {
    padding: 0 ${getVwMobile(74)};
  }

  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: ${getVw(33)};
    line-height: ${getVw(42)};
    margin-bottom: ${getVw(42)};
    letter-spacing: -0.02em;
    /* Main colour palette/Main Grey */
    color: #3D3D3E;

    @media only screen and (max-width: 768px) {
      position: absolute;
      top: ${getVwMobile(80)};
      font-size: ${getVwMobile(27)};
      margin: 0 auto;
      line-height: ${getVwMobile(34)};
      color: white;
      width: 100%;
      left: 0;
      text-align: center;
    }
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: ${getVw(16)};
    line-height: ${getVw(24)};
    letter-spacing: -0.02em;
    color: #3D3D3E;
    @media only screen and (max-width: 768px) {
      font-size: ${getVwMobile(16)};
      line-height: ${getVwMobile(24)};
    }
  }
`
