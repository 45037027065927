import {Menu, MenuButton, MenuItem} from "@szhsin/react-menu";
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/core.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import styled from "styled-components";
import {useTranslations} from "../../../common/providers/TranslationsProvider/TranslationsProvider";
import {getVw, getVwMobile} from "../../../utils/cssUtils";
import {colors} from "../../../common/styles/colors";

const languageNameMap: Record<string, string> = {
  'en': 'English',
  'de': 'German',
  'jp': 'Japan'
}
const StyledMenuButton = styled(MenuButton)`
  border: none;
  background: transparent;
  cursor: pointer;
  font-style: normal;
  font-weight: 450;
  font-size: ${getVw(16)};
  letter-spacing: -0.02em;
  display: flex;
  align-items: center;
  margin: 0 ${getVw(17)};
  transition: all 0.5s ease;
  padding-left: ${getVw(17)};;
  border-left: 1px solid ${colors.black};

  #desktop-lang {
    @media only screen and (max-width: 700px) {
      display: none;
    }
  }

  #mobile-lang {
    display: none;
    @media only screen and (max-width: 700px) {
      display: flex;
      font-size: ${getVwMobile(25)};
      color: white !important;
      line-height: ${getVwMobile(30)};
      width: 30%;
      border: none;
      margin-left: ${getVwMobile(10)};
    }
  }

  &:hover {
    color: #1BA148;
  }

  text-decoration: none;
  outline: none;
  color: ${colors.black};
  @media only screen and (max-width: 700px) {
    display: flex;
    font-size: ${getVwMobile(27)};
    color: white !important;
    line-height: ${getVwMobile(34)};
    margin-right: 0 !important;
    width: 100%;
    border: none;
    padding: ${getVwMobile(22)} 0;
    margin-top: ${getVwMobile(50)};
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
`

const StyledMenuItem = styled(MenuItem)`
  font-weight: 450;
  font-family: 'Circular', sans-serif !important;
  @media only screen and (max-width: 700px) {
    font-weight: 450;
    font-size: ${getVwMobile(27)};
    line-height: ${getVwMobile(34)};
    padding: ${getVwMobile(16)};
  }

`

export const LanguageSelector = () => {
  const {locale, setLocale} = useTranslations();
  return (
    <Menu menuButton={
      <StyledMenuButton><span id="mobile-lang">{languageNameMap[locale]}</span><span
        id="desktop-lang">{locale.toUpperCase()}</span></StyledMenuButton>
    } transition>
      <StyledMenuItem disabled={locale === 'en'} onClick={() => setLocale('en')}>English</StyledMenuItem>
      <StyledMenuItem disabled={locale === 'de'} onClick={() => setLocale('de')}>German</StyledMenuItem>
      <StyledMenuItem disabled={locale === 'jp'} onClick={() => setLocale('jp')}>Japan</StyledMenuItem>
    </Menu>
  )
}
