import React from "react";
import {BrandsWrapper, CarouselImageWrapper, CarouselWrapper} from "./Brands.styles";
import {SectionHeader} from "../../common/common";
import {Swiper, SwiperSlide} from "swiper/react";
import {A11y, Navigation, Pagination} from 'swiper';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import img1 from './img/1.svg'
import img2 from './img/2.svg'
import img3 from './img/3.svg'
import img4 from './img/4.svg'
import {FormattedMessage} from "react-intl";


export const Brands: React.FC = () => {

  return (
    <BrandsWrapper>
      <SectionHeader><FormattedMessage id="clients.title"/></SectionHeader>
      <p><FormattedMessage id="clients.subtitle"/></p>
      <CarouselWrapper>
        <Swiper
          loop
          grabCursor
          pagination={{
            clickable: true
          }}
          updateOnWindowResize
          autoHeight={false}
          resizeObserver
          updateOnImagesReady
          loopAdditionalSlides={0}
          preloadImages
          modules={[Navigation, Pagination, A11y]}
          slidesPerView={2}
          navigation
          breakpoints={
            {
              768: {
                slidesPerView: 4
              }
            }
          }
        >
          <SwiperSlide>
            <CarouselImageWrapper>
              <img src={img1} alt=""/>
            </CarouselImageWrapper>
          </SwiperSlide>
          <SwiperSlide>
            <CarouselImageWrapper>
              <img src={img2} alt=""/>
            </CarouselImageWrapper>
          </SwiperSlide>
          <SwiperSlide>
            <CarouselImageWrapper>
              <img src={img3} alt=""/>
            </CarouselImageWrapper>
          </SwiperSlide>
          <SwiperSlide>
            <CarouselImageWrapper>
              <img src={img4} alt=""/>
            </CarouselImageWrapper>
          </SwiperSlide>
        </Swiper>
      </CarouselWrapper>

    </BrandsWrapper>
  )
}
