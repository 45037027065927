import {useMutation} from "react-query";
import axios, {AxiosResponse} from "axios";
import {UseMutationOptions} from "react-query/types/react/types";


type ContactData = {
  name: string,
  phone: string,
  email?: string,
  message?: string
}


const URL = 'https://rinfde.azurewebsites.net/send';

export const useGetInTouch = (options?: Omit<UseMutationOptions<AxiosResponse, unknown, ContactData>, "mutationFn"> | undefined) => {
  console.log(URL);
  return useMutation(
    async (data: ContactData) => axios.post(URL, data),
    options
  )
}
