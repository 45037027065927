import React from 'react';
import './App.css';
import {Header} from "./components/Header/Header";
import {Banner} from "./components/Banner/Banner";
import './index.css'
import {Tiles} from "./components/Tiles/Tiles";
import {Technologies} from "./components/Technologies/Technologies";
import {Brands} from "./components/Brands/Brands";
import {Benefits} from "./components/Benefits/Benefits";
import {Reviews} from "./components/Reviews/Reviews";
import {Portfolio} from "./components/Portfolio/Portfolio";
import {Contact} from "./components/Contact/Contact";
import {Services} from "./components/Services/Services";
import {Footer} from "./components/Footer/Footer";

import {library} from '@fortawesome/fontawesome-svg-core'
import {fab} from '@fortawesome/free-brands-svg-icons'
import {faBars} from '@fortawesome/free-solid-svg-icons'
import {faCircleCheck} from "@fortawesome/free-solid-svg-icons";
import {faFaceFrown} from "@fortawesome/free-solid-svg-icons";
import {SubHeadingMobile} from "./components/Banner/Banner.styles";
import {FormattedMessage} from "react-intl";
import styled from "styled-components";


library.add(fab, faBars, faCircleCheck, faFaceFrown);


const CommonStylesWrapper = styled('div')`
  &:not(.szh-menu-button) {
    animation-duration: 0.4s;
    animation-name: animate-fade;
    animation-delay: 0s;
    animation-fill-mode: backwards;
  }
`


function App() {
  return (
    <CommonStylesWrapper>
      <Header/>
      <Banner/>
      <SubHeadingMobile>
        <p>
          <strong><FormattedMessage id="banner.subheading.bold"/></strong>,
          <br/>
          <FormattedMessage id="banner.subheading.normal"/>
        </p>
      </SubHeadingMobile>
      <Tiles/>
      <Services/>
      <Technologies/>
      <Brands/>
      <Benefits/>
      <Reviews/>
      <Portfolio/>
      <Contact/>
      <Footer/>
    </CommonStylesWrapper>
  );
}

export default App;
