import styled from "styled-components";
import {getVw, getVwMobile} from "../../utils/cssUtils";

export const BrandsWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #F1F1F1;
  padding: ${getVw(74)};
  padding-bottom: ${getVw(74 * 2)};

  @media only screen and (max-width: 768px) {
    padding: ${getVwMobile(50)};
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: ${getVw(24)};
    line-height: ${getVw(31)};
    /* or 130% */

    text-align: center;
    letter-spacing: -0.02em;

    color: #3D3D3E;
    margin-bottom: 0;

    @media only screen and (max-width: 768px) {
      margin-top: 0;
      font-size: ${getVwMobile(16)};
      line-height: ${getVwMobile(22)};
    }
  }
`

export const CarouselWrapper = styled('div')`
  width: ${getVw(1440 - (68 * 2))};
  .swiper {
    padding: ${getVw(80)} 0;
    @media only screen and (max-width: 768px) {
      padding: ${getVwMobile(50)} 0;
    }
    .swiper-slide {
      display: flex;
      justify-content: center;
      height: unset;
    }
    .swiper-wrapper {
      align-items: stretch;
    }
    .swiper-pagination-bullet {
      width: ${getVw(10)};
      height: ${getVw(10)};

      background: transparent;
      border: 2px solid #848484;
      opacity: 1 !important;
      &-active {
        background: #848484;
      }
      @media only screen and (max-width: 768px) {
        width: ${getVwMobile(7)};
        height: ${getVwMobile(7)};
      }
    }
    .swiper-button-prev, .swiper-button-next {
      color: #848484;
      &:after {
        @media only screen and (max-width: 768px) {
          font-size: ${getVwMobile(25)};
        }
      }
    }
  }
`

export const CarouselImageWrapper = styled('div')`
  display: flex;
  height: 100%;
  width: ${getVw(200)};
  @media only screen and (max-width: 768px) {
    width: ${getVwMobile(130)};
  }

  img {
    width: 100%;
    height: 100%;
  }
`
