import styled from "styled-components";
import {getVw, getVwMobile} from "../../utils/cssUtils";

export const PortfolioWrapper = styled('div')`
  padding: ${getVw(68)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    padding: ${getVwMobile(50)}
  }

  p {
    font-style: normal;
    font-weight: 300;
    font-size: ${getVw(33)};
    line-height: ${getVw(47)};
    text-align: center;
    letter-spacing: -0.02em;
    color: #3D3D3E;
    max-width: ${getVw(960)};
    margin: 0 0 ${getVw(80)};

    @media only screen and (max-width: 768px) {
      font-size: ${getVwMobile(16)};
      line-height: ${getVwMobile(24)};
      max-width: ${getVwMobile(300)};
      margin: 0 0 ${getVwMobile(30)};
    }
  }
`

export const PortfolioCardsWrapper = styled('div')`
  display: flex;
  align-items: stretch;
  margin-bottom: ${getVw(100)};;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`


export const PortfolioCard = styled('a')`
  text-decoration: none;
  width: ${getVw(320)};
  display: flex;
  flex-direction: column;
  &:not(:last-child){
    margin-right: ${getVw(50)};
  }

  @media only screen and (max-width: 768px) {
    margin-right: 0 !important;
    margin-bottom: ${getVwMobile(35)};;
    width: 100%;
  }

  img {
    width: 100%;
  }

  div {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: ${getVw(20)};
    background: #1BA148;
    box-shadow: 6px 6px 30px -3px rgba(0, 0, 0, 0.3);

    @media only screen and (max-width: 768px) {
      padding: ${getVwMobile(20)};
    }
    h3 {
      font-style: normal;
      font-weight: 700;
      font-size: ${getVw(33)};
      line-height: ${getVw(28)};
      letter-spacing: -0.02em;
      text-align: center;
      color: #FFFFFF;
      margin: 0 0 ${getVw(20)};
      height: ${getVw(50)};
      @media only screen and (max-width: 768px) {
        font-size: ${getVwMobile(33)};
        line-height: ${getVwMobile(29)};
        margin: 0 0 ${getVwMobile(20)};
        height: auto;
      }
    }

    p {
      margin: 0;
      font-style: normal;
      font-weight: 450;
      font-size: ${getVw(16)};
      line-height: ${getVw(22)};
      letter-spacing: -0.02em;
      color: #FFFFFF;
      @media only screen and (max-width: 768px) {
        font-size: ${getVwMobile(16)};
        line-height: ${getVwMobile(21)};
        text-align: left;
      }
    }
  }
`
