import React, {PropsWithChildren} from "react";
import {AccordionButton, AccordionContent, AccordionHeader, AccordionTitle, AccordionWrapper} from "./Accordion.styles";

import closeIcon from './img/close.svg'
import openIcon from './img/open.svg'


type AccordionProps = {
  isOpen?: boolean,
  onOpen?: (id: string) => void,
  id?: string,
  title: string,
}


export const Accordion: React.FC<PropsWithChildren<AccordionProps>> = (
  {
    isOpen = false,
    onOpen,
    id,
    title,
    children
  }) => {

  const toggleOpen = () => {
    if (onOpen) {
      if (id != null) {
        onOpen(id);
      }
    }
  };

  return (
    <AccordionWrapper id={`accordion-${id}`}>
      <AccordionHeader onClick={toggleOpen}>
        <AccordionTitle>{title}</AccordionTitle>
        <AccordionButton isOpen={isOpen}>
          <img src={isOpen ? closeIcon : openIcon} alt=""/>
        </AccordionButton>

      </AccordionHeader>
      {isOpen && <AccordionContent>
        <p>
          {children}
        </p>
      </AccordionContent>}
    </AccordionWrapper>
  )
}
